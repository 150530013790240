<template>
  <div class="page">
    <Nav ref="navigation"/>
    <main><!-- 
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition> -->
      <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    </main>
    <Footer/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ref } from 'vue'

import Nav from './layout/nav.vue';
import Footer from './layout/footer.vue';

export default defineComponent({
  name: 'App',
  components: {
    Nav,
    Footer
  }, 
  setup() {
      const navigation = ref(null)
      const main = ref(null)
      
      return {
        navigation,
        main
      }
  }
});
</script>

<style lang="scss">
@import './resources/styles/style.scss' ;

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
