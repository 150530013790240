import { createRouter, createWebHistory, RouteRecordRaw,  } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home.vue')

  },
  {
    path: '/produkty',
    name: 'Produkty',
    component: () => import('../views/products/products.vue'),
    props: route => ({
      type: 'products', 
      product: 'none'
    })
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/home.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return  { 
        el: to.hash, 
        top: 100, 
        behavior: 'smooth', 
      }
    }
  }
})

export default router
