
import { defineComponent } from 'vue';
import { ref } from 'vue'

import Nav from './layout/nav.vue';
import Footer from './layout/footer.vue';

export default defineComponent({
  name: 'App',
  components: {
    Nav,
    Footer
  }, 
  setup() {
      const navigation = ref(null)
      const main = ref(null)
      
      return {
        navigation,
        main
      }
  }
});
