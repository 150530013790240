import { createStore } from 'vuex'

export default createStore({
    state: {
      interiorDoors: [
        { 
          id: 'unico',
          name: 'unico', 
          title: 'Modelová řada UNICO – nejdokonalejší spojení dveří a zárubní',
          description: '<p>Modelová řada UNICO vznikla spojením zárubní s unikátní konstrukcí a pečlivě vybranými nejatraktivnějšími modely dveří z produkce SOLODOOR.</p>' +
                '<p>Naprosté dokonalosti tohoto mistrovského díla je docíleno jednak díky zárubním s netradičním způsobem napojení svislých a vodorovných částí v kombinaci s trendy modelem dveří opatřených magnetickým zámkem.</p>' +
                '<p>Vše navíc v exkluzivním povrchu SOLO MATRIX.</p>',
          url: 'https://www.solodoor.cz/cs/interierove-dvere/unico/', 
          list: [
            {
              id: 'unico-plne',
              name: 'unico plné',
              configPath: 'https://www.sestavsidvere.cz/cs/unico/unico-plne/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/42/b42000970.jpg',
            },
            {
              id: 'unico-5',
              name: 'unico 5',
              configPath: 'https://www.sestavsidvere.cz/cs/unico/unico-5/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/42/b42005969_2400301.jpg',
            },
            {
              id: 'unico-11',
              name: 'unico 11',
              configPath: 'https://www.sestavsidvere.cz/cs/unico/unico-11/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/42/b42011971_2400301.jpg',
            },
            {
              id: 'unico-12',
              name: 'unico 12',
              configPath: 'https://www.sestavsidvere.cz/cs/unico/unico-12/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/42/b42012947.jpg',
            },
            {
              id: 'unico-21',
              name: 'unico 21',
              configPath: 'https://www.sestavsidvere.cz/cs/unico/unico-21/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/42/b42021920_2530001.jpg',
            },
            {
              id: 'unico-22',
              name: 'unico 22',
              configPath: 'https://www.sestavsidvere.cz/cs/unico/unico-22/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/42/b42022921.jpg',
            },
            {
              id: 'unico-31',
              name: 'unico 31',
              configPath: 'https://www.sestavsidvere.cz/cs/unico/unico-31/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/42/b42031969_2530001.jpg',
            },
            {
              id: 'unico-32',
              name: 'unico 32',
              configPath: 'https://www.sestavsidvere.cz/cs/unico/unico-32/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/42/b42032969.jpg',
            },
            {
              id: 'unico-41',
              name: 'unico 41',
              configPath: 'https://www.sestavsidvere.cz/cs/unico/unico-41/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/42/b42041969_2530001.jpg',
            },
            {
              id: 'unico-42',
              name: 'unico 42',
              configPath: 'https://www.sestavsidvere.cz/cs/unico/unico-42/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/42/b42042988.jpg',
            }
          ]
        },
        { 
          id: 'rainbow',
          name: 'rainbow', 
          title: 'Dveře Rainbow – okouzlí Vás svým rafinovaným vzhledem',
          description: '<ul class="list"><li>Trendy barva kombinovatelná s každým interiérem</li>' +
              '<li>Exkluzivní vícevrstvé lakování s vysokou odolností</li>' + 
              '<li>Ekologicky šetrný výrobek</li>' +
              '<li>Dřevotřísková výplň ve standardu</li>' +
              '<li>Lze též jako posuvné dveře</li>' +
              '<li>Již ve standardu v provedení KLIMA I</li></ul>',
          url: 'https://www.solodoor.cz/cs/interierove-dvere/rainbow/',   
          list: [
            {
              id: 'rainbow-plne',
              name: 'rainbow plné',
              configPath: 'https://www.sestavsidvere.cz/cs/rainbow/rainbow-plne/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/26/b26000119.jpg'
            },
            {
              id: 'rainbow-1',
              name: 'rainbow 1',
              configPath: 'https://www.sestavsidvere.cz/cs/rainbow/rainbow-1/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/26/b26001119.jpg'
            },
            {
              id: 'rainbow-4',
              name: 'rainbow 4',
              configPath: 'https://www.sestavsidvere.cz/cs/rainbow/rainbow-4/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/26/b26004119.jpg'
            },
            
            {
              id: 'rainbow-5',
              name: 'rainbow 5',
              configPath: 'https://www.sestavsidvere.cz/cs/rainbow/rainbow-5/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/26/b26005119_2300003.jpg'
            },
            
            {
              id: 'rainbow-7',
              name: 'rainbow 7',
              configPath: 'https://www.sestavsidvere.cz/cs/rainbow/rainbow-7/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/26/b26007119.jpg'
            },
            
            {
              id: 'rainbow-8',
              name: 'rainbow 8',
              configPath: 'https://www.sestavsidvere.cz/cs/rainbow/rainbow-8/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/26/b26008119_2300003.jpg'
            },
            
            {
              id: 'rainbow-9',
              name: 'rainbow 9',
              configPath: 'https://www.sestavsidvere.cz/cs/rainbow/rainbow-9/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/26/b26009119.jpg'
            },
            
            {
              id: 'rainbow-17',
              name: 'rainbow 17',
              configPath: 'https://www.sestavsidvere.cz/cs/rainbow/rainbow-17/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/26/b26017119.jpg'
            },
            
            {
              id: 'rainbow-18',
              name: 'rainbow 18',
              configPath: 'https://www.sestavsidvere.cz/cs/rainbow/rainbow-18/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/26/b26018119.jpg'
            },
            {
              id: 'rainbow-19',
              name: 'rainbow 19',
              configPath: 'https://www.sestavsidvere.cz/cs/rainbow/rainbow-22/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/26/b26022119.jpg'
            },
            {
              id: 'rainbow-24',
              name: 'rainbow 24',
              configPath: 'https://www.sestavsidvere.cz/cs/rainbow/rainbow-24/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/26/b26024119.jpg'
            },
          ]
        },
        { 
          id: 'vertigo',
          name: 'vertigo' , 
          title: 'Dveře VERTIGO – moderní design a rámová konstrukce | SOLODOOR',
          description: '<p>Poctivé dveře, které vydrží. Robustní interiérové dveře VERTIGO vám přinesou mnoho výhod - mají unikátní <b>pevnou a stabilní rámovou konstrukci</b>, nabízejí <b>mnoho variant<b/> skladby kazet a prosklení a disponují vysokou odolností povrchu se snadnou údržbou. Díky svému <b>nadčasovému designu</b> skvěle zapadnou do každého moderního interiéru.</p>' +
            '<p>Tam, kde potřebujete ušetřit prostor, můžete dveře VERTIGO instalovat jako <b>posuvné</b>. Hodí se vám do interiéru klasické dveře <b>falcové</b>, nebo by působily lépe <b>bezfalcové</b>? Falcové dveře si můžete objednat i s elegantní <b>oblou hranou.</b></p>' +
            '<p>Na konfigurátoru dveří si schválně vyzkoušejte, v jakém dekoru a provedení by se vám dveře VERTIGO nejvíce líbily. Zapadnou k vám domů? Na to se podívejte zde.</p>',
          url: 'https://www.solodoor.cz/cs/interierove-dvere/vertigo/',   
          list: [
            {
              id: 'vertigo-1',
              name: 'vertigo 1',
              configPath: 'https://www.sestavsidvere.cz/cs/vertigo/vertigo-1/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/21/b21001844.jpg',
            },
            {
              id: 'vertigo-2',
              name: 'vertigo 2',
              configPath: 'https://www.sestavsidvere.cz/cs/vertigo/vertigo-2/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/21/b21002844.jpg',
            },
            {
              id: 'vertigo-3',
              name: 'vertigo 3',
              configPath: 'https://www.sestavsidvere.cz/cs/vertigo/vertigo-3/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/21/b21003844.jpg',
            },
            {
              id: 'vertigo-4',
              name: 'vertigo 4',
              configPath: 'https://www.sestavsidvere.cz/cs/vertigo/vertigo-4/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/21/b21004844.jpg',
            },
            {
              id: 'vertigo-10',
              name: 'vertigo 10',
              configPath: 'https://www.sestavsidvere.cz/cs/vertigo/vertigo-10/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/21/b21010844.jpg',
            },
            {
              id: 'vertigo-11',
              name: 'vertigo 11',
              configPath: 'https://www.sestavsidvere.cz/cs/vertigo/vertigo-11/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/21/b21011844.jpg',
            },
            {
              id: 'vertigo-20',
              name: 'vertigo 20',
              configPath: 'https://www.sestavsidvere.cz/cs/vertigo/vertigo-20/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/21/b21020844.jpg',
            },
            {
              id: 'vertigo-21',
              name: 'vertigo 21',
              configPath: 'https://www.sestavsidvere.cz/cs/vertigo/vertigo-21/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/21/b21021844.jpg',
            },
            {
              id: 'vertigo-51',
              name: 'vertigo 51',
              configPath: 'https://www.sestavsidvere.cz/cs/vertigo/vertigo-51/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/21/b21051844.jpg',
            },
            {
              id: 'vertigo-52',
              name: 'vertigo 52',
              configPath: 'https://www.sestavsidvere.cz/cs/vertigo/vertigo-52/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/21/b21052844.jpg',
            },
            {
              id: 'vertigo-55',
              name: 'vertigo 55',
              configPath: 'https://www.sestavsidvere.cz/cs/vertigo/vertigo-55/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/21/b21055844.jpg',
            },
            {
              id: 'vertigo-56',
              name: 'vertigo 56',
              configPath: 'https://www.sestavsidvere.cz/cs/vertigo/vertigo-56/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/cs/vertigo/vertigo-56/?mena=czk&lng=cs',
            },
          ]
        },
        { 
          id: 'vertigo-variant',
          name: 'vertigo variant' , 
          title: 'Moderní interiérové dveře VERTIGO VARIANT| SOLODOOR',
          description: '<p>TODO</p>',
          url: 'https://www.solodoor.cz/cs/interierove-dvere/vertigo-variant/', 
        },
        { 
          id: 'polar',
          name: 'polar' , 
          title: 'Dveře POLAR – bílá je světový trend',
          description: '<ul class="list"><li>Sněhobílá barva kombinovatelná s každým interiérem</li>' +
            '<li>Exkluzivní vícevrstvé lakování s vysokou odolností</li>' +
            '<li>Ekologicky šetrný výrobek</li>' +
            '<li>Dřevotřísková výplň ve standardu</li>' +
            '<li>Lze též jako posuvné dveře</li>' +
            '<li>Již ve standardu v provedení KLIMA I</li></ul>',
          url: 'https://www.solodoor.cz/cs/interierove-dvere/polar/',   
          list: [
            {
              id: 'polar-plna',
              name: 'polar plná',
              configPath: 'https://www.sestavsidvere.cz/cs/polar/polar-plne/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/22/b22000116.jpg',
            },
            {
              id: 'polar-1',
              name: 'polar 1',
              configPath: 'https://www.sestavsidvere.cz/cs/polar/polar-1/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/22/b22001116.jpg',
            },
            {
              id: 'polar-4',
              name: 'polar 4',
              configPath: 'https://www.sestavsidvere.cz/cs/polar/polar-4/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/22/b22004116.jpg',
            },
            {
              id: 'polar-5',
              name: 'polar 5',
              configPath: 'https://www.sestavsidvere.cz/cs/polar/polar-5/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/22/b22005116_2300001.jpg',
            },
            {
              id: 'polar-7',
              name: 'polar 7',
              configPath: 'https://www.sestavsidvere.cz/cs/polar/polar-7/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/22/b22007116.jpg',
            },
            {
              id: 'polar-8',
              name: 'polar 8',
              configPath: 'https://www.sestavsidvere.cz/cs/polar/polar-8/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/22/b22008116_2300001.jpg',
            },
            {
              id: 'polar-9',
              name: 'polar 9',
              configPath: 'https://www.sestavsidvere.cz/cs/polar/polar-9/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/22/b22009116.jpg',
            },
            {
              id: 'polar-17',
              name: 'polar 17',
              configPath: 'https://www.sestavsidvere.cz/cs/polar/polar-17/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/22/b22017116.jpg',
            },
            {
              id: 'polar-18',
              name: 'polar 18',
              configPath: 'https://www.sestavsidvere.cz/cs/polar/polar-18/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/22/b22018116.jpg',
            },
            {
              id: 'polar-22',
              name: 'polar 22',
              configPath: 'https://www.sestavsidvere.cz/cs/polar/polar-22/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/22/b22022116.jpg',
            },
            {
              id: 'polar-24',
              name: 'polar 24',
              configPath: 'https://www.sestavsidvere.cz/cs/polar/polar-24/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/22/b22024116.jpg',
            }
          ]
        },
        { 
          id: 'sonet',
          name: 'sonet' , 
          title: 'Dveře SONET – moderní dveře s osobitou konstrukcí',
          description: '<p>Elegantní a moderní řada pokojových dveří <b>SONET</b> vnese do interiéru notnou dávku luxusu, a to především díky kombinaci dřeva a skla. Interiérové prosklené dveře <b>SONET</b> mají bezpečnostní 8mm sklo, které je do dveří vsazeno tak, že je přímo součástí konstrukce.</p>'+
            '<ul class="list"><li>Díky bezpečnostnímu 8 mm bezpečnostnímu sklu  získáváte záruku odolnosti a bezpečí i ve velmi frekventovaných prostorách</li>' +
            '<li>Dveře SONET mají velmi odolný povrch se snadnou údržbou</li>' +
            '<li>Vyberte si ze široké nabídky vzorů pískovaných vzorů skel</li></ul>',
          url: 'https://www.solodoor.cz/cs/interierove-dvere/sonet/',   
          list: [
            {
              id: 'sonet-1',
              name: 'sonet 1',
              configPath: 'https://www.sestavsidvere.cz/cs/sonet/sonet-1/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/14/b14001825_2510160.jpg',
            },
            {
              id: 'sonet-2',
              name: 'sonet 2',
              configPath: 'https://www.sestavsidvere.cz/cs/sonet/sonet-2/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/14/b14002825_2400301.jpg',
            }
          ]
        },
        { 
          id: 'elegance',
          name: 'elegance' , 
          title: 'Dveře ELEGANCE – přírodní dekory dřeva v kombinaci s kovem a sklem',
          description: '<p>Interiérové dveře řady Elegance zaujmou nadčasovým vzhledem, kterého je docíleno kombinací přírodních dekorů dřeva se sklem a vertikálními či horizontálními lištami, které dveře decentním způsobem ozvláštní.</p>'+
          '<ul class="list"><li>Mix zvolených materiálů dodává moderním dveřím nádech luxusu – v interiéru se tak dveře ELEGANCE stanou zajímavým doplňkem</li>' +
          '<li>Moderní dveře s neotřelým vzhledem</li>' +
          '<li>Lišty s moderním kovovým efektem</li>' +
          '<li>Vysoká odolnost povrchu s imitací dřeva</li></ul>',
          url: 'https://www.solodoor.cz/cs/interierove-dvere/elegance/', 
        },
        { 
          id: 'linie',
          name: 'linie' , 
          title: 'Dveře LINIE',
          description: '<p>Základem pro tyto interiérové dveře je oblíbený 3D povrch v dekoru cremeline, který se hodí do každého interiéru. Nápaditým detailem jsou vsazené decentní proužky v dekoru dřeva, díky kterým dveře nepostrádají nápad a vkus</p>'+
          '<ul class="list"><li>Dveře vhodné pro všechny typy interiérů </li>' +
          '<li>Vysoce odolný 3D povrch</li>' +
          '<li>Netradiční spojení krémové barvy a proužku s dřevinným dekorem</li></ul>',
          url: 'https://www.solodoor.cz/cs/interierove-dvere/linie/',   
        },
        { 
          id: 'sima',
          name: 'sima' , 
          title: 'Dveře SIMA – když se z řemesla stane umění',
          description: '<p>Modelová řada interiérových dveří SIMA zaujme hladkým řemeslným detailem v podobě zapuštěných rámečků u prosklení. Interiérové dveře SIMA jsou tak synonymem moderních dveří, které nejen skvěle vypadají, ale mají i praktické výhody - díky zapuštěné hraně rámečku prosklení se nemůžete zachytit o oděv a tím tak dveře poškodit.</p>'+
          '<ul class="list"><li>Unikátní řemeslný detail v podobě zapuštěných rámečků, za které se jen tak nezachytíte </li>' +
          '<li>Snadná údržba dveří díky jednolitému povrchu</li>' +
          '<li>Nadčasový design, který jen tak nevyjde z módy</li>' +
          '<li>Vysoká odolnost povrchu, snadná údržba, atraktivní vzhled</li>' +
          '<li>Díky zapuštěným rámečkům lze tyto dveře použít do stavebních pouzder na šíři zdi 10 cm</li></ul>',
          url: 'https://www.solodoor.cz/cs/interierove-dvere/sima/', 
          list: [
            {
              id: 'sima-1',
              name: 'sima 1',
              configPath: 'https://www.sestavsidvere.cz/cs/sima/sima-1/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/27/b27001842_2300010.jpg',
            },
            {
              id: 'sima-2',
              name: 'sima 2',
              configPath: 'https://www.sestavsidvere.cz/cs/sima/sima-2/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/27/b27002842.jpg',
            },{
              id: 'sima-11',
              name: 'sima 11',
              configPath: 'https://www.sestavsidvere.cz/cs/sima/sima-11/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/27/b27011842_2300010.jpg',
            },{
              id: 'sima-12',
              name: 'sima 12',
              configPath: 'https://www.sestavsidvere.cz/cs/sima/sima-12/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/27/b27012842.jpg',
            },{
              id: 'sima-21',
              name: 'sima 21',
              configPath: 'https://www.sestavsidvere.cz/cs/sima/sima-21/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/27/b27021842_2300010.jpg',
            },{
              id: 'sima-22',
              name: 'sima 22',
              configPath: 'https://www.sestavsidvere.cz/cs/sima/sima-22/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/27/b27022842.jpg',
            }
          ]
        },
        { 
          id: 'cristal',
          name: 'cristal' , 
          title: 'CRISTAL, dveře které spojují',
          description: '<p>Celoskleněné dveře od sebe interiéry neoddělují, naopak je spojují. Prosklené dveře jsou tak vhodné do místností, kde nevyžadujete soukromí, ale naopak co nejvíce světla a prostoru.</p>'+
            '<p>Pro skleněné dveře můžete vybírat hned z několika možností skleněných výplní dveří: čisté, jednoduché dveře, decentní zdobené dveře, kreativnější pískované dveře, či dveře rýhované.</p>'+
            '<ul class="list"><li>Profilace skla na straně s panty (vzdálenější strana od zárubní) </li>' +
            '<li>Tyto dveře patří do speciální zárubně (krátký protiplech, dva závěsy)</li>' +
            '<li>Pestrá paleta motivů a variant skleněných výplní dveří</li>' +
            '<li>Dveře propouštějí světlo a opticky zvětší prostor</li>' +
            '<li>Vyrobeno z bezpečnostního skla šíře 8 mm</li></ul>' +
            '<p>Co myslíte – které modely se hodí více do kancelářských komplexů a které by naopak dodaly originální kouzlo vašemu bytu? Pokud si nejste jistí, vyzkoušejte si zde, jak by které dveře u vás doma vypadaly.</p>', 
          url: 'https://www.solodoor.cz/cs/interierove-dvere/cristal/', 
          list: [
            {
              id: 'cristal-satinato',
              name: 'Standardní SATINATO',
              configPath: 'https://www.sestavsidvere.cz/cs/cristal/standardni-satinato/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/44/b44001000.jpg',
            },
            {
              id: 'cristal-klar',
              name: 'Standardní KLAR',
              configPath: 'https://www.sestavsidvere.cz/cs/cristal/standardni-klar/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/44/b44002000.jpg',
            },
            {
              id: 'cristal-sr178',
              name: 'Standardní SR 178 bílá',
              configPath: 'https://www.sestavsidvere.cz/cs/cristal/standardni-sr-178-bila/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/44/b44003000.jpg',
            },
            {
              id: 'cristal-balken',
              name: 'Standardní BALKEN',
              configPath: 'https://www.sestavsidvere.cz/cs/cristal/standardni-balken/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/44/b44004000.jpg',
            },
            {
              id: 'cristal-horizont',
              name: 'Standardní HORIZONT',
              configPath: 'https://www.sestavsidvere.cz/cs/cristal/standardni-horizont/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/44/b44005000.jpg',
            },
            {
              id: 'cristal-cr031p',
              name: 'Pískované CRO31P',
              configPath: 'https://www.sestavsidvere.cz/cs/cristal/piskovane-cro31p/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/44/b44006000.jpg',
            },
            {
              id: 'cristal-diagon',
              name: 'Pískované DIAGON 1',
              configPath: 'https://www.sestavsidvere.cz/cs/cristal/piskovane-diagon-1/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/44/b44007000.jpg',
            },
            {
              id: 'cristal-togo',
              name: 'Pískované TOGO',
              configPath: 'https://www.sestavsidvere.cz/cs/cristal/piskovane-togo/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/44/b44008000.jpg',
            },
            {
              id: 'cristal-line2n',
              name: 'Pískované LINE 2 N',
              configPath: 'https://www.sestavsidvere.cz/cs/cristal/piskovane-line-2-n/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/44/b44009000.jpg',
            },
            {
              id: 'cristal-902p',
              name: 'Pískované 902-P',
              configPath: 'https://www.sestavsidvere.cz/cs/cristal/piskovane-902-p/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/44/b44010000.jpg',
            },
            {
              id: 'cristal-equisist',
              name: 'Rýhované EXQUISIST 16',
              configPath: 'https://www.sestavsidvere.cz/cs/cristal/ryhovane-exquisist-16/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/44/b44011000.jpg',
            },
            {
              id: 'cristal-equisist20',
              name: 'Rýhované EXQUISIST 20',
              configPath: 'https://www.sestavsidvere.cz/cs/cristal/ryhovane-exquisist-20/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/44/b44012000.jpg',
            },
            {
              id: 'cristal-equisist25',
              name: 'Rýhované EXQUISIST 25',
              configPath: 'https://www.sestavsidvere.cz/cs/cristal/ryhovane-exquisist-25/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/44/b44013000.jpg',
            },
            {
              id: 'cristal-equisist12',
              name: 'Rýhované EXQUISIST 12',
              configPath: 'https://www.sestavsidvere.cz/cs/cristal/ryhovane-exquisist-12/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/44/b44014000.jpg',
            },
            {
              id: 'cristal-equisist11',
              name: 'Rýhované EXQUISIST 11',
              configPath: 'https://www.sestavsidvere.cz/cs/cristal/ryhovane-exquisist-11/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/44/b44015000.jpg',
            },
          ]
        },
        { 
          id: 'gabreta',
          name: 'gabreta' , 
          title: 'Dveře GABRETA – bestseller s nestárnoucím designem',
          description: '<p>Modelová řada dveří GABRETA patří mezi nejprodávanější modely interiérových dveří společnosti SOLODOOR, a to především díky svému elegantnímu, jednoduchému a nadčasovému designu.</p>'+
            '<p>Na výběr je hned z několika tvarů prosklení v podobě rovného či zaobleného pruhu.</p>'+
            '<ul class="list"><li>Nestárnoucí design dveří </li>' +
            '<li>Vkusná kombinace dřevěného dekoru a skla</li>' +
            '<li>Dlouholetý bestseller, oblíbená volba mnoha domácností</li>' +
            '<li>Vysoká odolnost povrchu pro snadnou údržbu</li>' +
            '<li>Široká škála povrchů imitujících dřevo</li></ul>',
          url: 'https://www.solodoor.cz/cs/interierove-dvere/gabreta/',   
          list: [
            {
              id: 'gabreta-1',
              name: 'gabreta 1',
              configPath: 'https://www.sestavsidvere.cz/cs/gabreta/gabreta-1/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/20/b20001240_2300010.jpg',
            },
            {
              id: 'gabreta-2',
              name: 'gabreta 2',
              configPath: 'https://www.sestavsidvere.cz/cs/gabreta/gabreta-2/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/20/b20002240_2300010.jpg',
            },{
              id: 'gabreta-3',
              name: 'gabreta 3',
              configPath: 'https://www.sestavsidvere.cz/cs/gabreta/gabreta-3/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/20/b20003240_2300010.jpg',
            },
            {
              id: 'gabreta-4',
              name: 'gabreta 4',
              configPath: 'https://www.sestavsidvere.cz/cs/gabreta/gabreta-4/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/20/b20004240_2300010.jpg',
            },{
              
              id: 'gabreta-9',
              name: 'gabreta 9',
              configPath: 'https://www.sestavsidvere.cz/cs/gabreta/gabreta-9/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/20/b20009240.jpg',
            }
          ]
        },
        { 
          id: 'klasik',
          name: 'klasik' , 
          title: 'Dveře KLASIK – evergreen mezi dveřmi',
          description: '<p>Název modelové řady KLASIK plně vystihuje, co řada představuje – tedy klasické dveře nestárnoucího designu. Vybírat lze hned z několika modelů dveří prosklených či plných.</p>'+
          '<ul class="list"><li>Jedna z nejžádanějších modelových řad </li>' +
          '<li>Nadčasový, klasický vzhled interiérových dveří</li>' +
          '<li>Vysoká odolnost povrchu, snadná údržba, atraktivní vzhled</li>' +
          '<li>Široká škála povrchů od dekorů imitujících dřevo až po barvy RAL</li></ul>',
          url: 'https://www.solodoor.cz/cs/interierove-dvere/klasik/',   
          list: [
            {
              id: 'klasik-plne',
              name: 'klasik plné',
              configPath: 'https://www.sestavsidvere.cz/cs/klasik/klasik-plne/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/1/b01000239.jpg',
            },
            {
              id: 'klasik-1',
              name: 'klasik 1',
              configPath: 'https://www.sestavsidvere.cz/cs/klasik/klasik-1/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/1/b01001239_2300003.jpg',
            },
            {
              id: 'klasik-2',
              name: 'klasik 2',
              configPath: 'https://www.sestavsidvere.cz/cs/klasik/klasik-2/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/1/b01002239_2300003.jpg',
            },
            {
              id: 'klasik-3',
              name: 'klasik 3',
              configPath: 'https://www.sestavsidvere.cz/cs/klasik/klasik-3/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/1/b01003239_2300003.jpg',
            },
            {
              id: 'klasik-5',
              name: 'klasik 5',
              configPath: 'https://www.sestavsidvere.cz/cs/klasik/klasik-5/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/1/b01005239_2300003.jpg',
            },
            {
              id: 'klasik-7',
              name: 'klasik 7',
              configPath: 'https://www.sestavsidvere.cz/cs/klasik/klasik-7/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/1/b01007239_2300003.jpg',
            }
          ]
        },
        { 
          id: 'viva',
          name: 'viva' , 
          title: 'DVEŘE VIVA – SPOJENÍ DESIGNU A FUNKČNOSTI',
          description: '<ul class="list"><li>Rámová konstrukce</li>'+
              '<li>Již ve standardu v provedení KLIMA I</li>'+
              '<li>Elegantní a výborně kombinovatelný vzhled</li>'+
              '<li>Ekologicky šetrný výrobek</li>'+
              '<li>Lze též jako posuvné dveře do pouzdra</li></ul>',
          url: 'https://www.solodoor.cz/cs/interierove-dvere/viva/', 
          list: [
            {
              id: 'viva-10',
              name: 'viva 10',
              configPath: 'https://www.sestavsidvere.cz/cs/viva/viva-10/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/43/b43210008.jpg',
            },
            {
              id: 'viva-11',
              name: 'viva 11',
              configPath: 'https://www.sestavsidvere.cz/cs/viva/viva-11/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/43/b43211008.jpg',
            },
            {
              id: 'viva-12',
              name: 'viva 12',
              configPath: 'https://www.sestavsidvere.cz/cs/viva/viva-12/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/43/b43212008.jpg',
            },
            {
              id: 'viva-20',
              name: 'viva 20',
              configPath: 'https://www.sestavsidvere.cz/cs/viva/viva-20/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/43/b43220008.jpg',
            },
            {
              id: 'viva-21',
              name: 'viva 21',
              configPath: 'https://www.sestavsidvere.cz/cs/viva/viva-21/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/43/b43221008.jpg',
            },
            {
              id: 'viva-30',
              name: 'viva 30',
              configPath: 'https://www.sestavsidvere.cz/cs/viva/viva-30/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/43/b43230008.jpg',
            },
            {
              id: 'viva-31',
              name: 'viva 31',
              configPath: 'https://www.sestavsidvere.cz/cs/viva/viva-31/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/43/b43231008.jpg',
            },
            {
              id: 'viva-50',
              name: 'viva 50',
              configPath: 'https://www.sestavsidvere.cz/cs/viva/viva-50/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/43/b43250008.jpg',
            },
            {
              id: 'viva-51',
              name: 'viva 51',
              configPath: 'https://www.sestavsidvere.cz/cs/viva/viva-51/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/43/b43251008.jpg',
            }
          ]
        },
        { 
          id: 'styl',
          name: 'styl' , 
          title: 'Dveře STYL – ověřený nestárnoucí design',
          description: '<p>Máte rádi tradiční vzhled, ale žít chcete moderně? Nás baví navazovat na staré styly a dále je rozvíjet. U modelové řady STYL jsme si pohráli s jedním typem dveří, jaký vyráběli truhlářští mistři za 1. republiky, a jejich <b>příjemnému designu</b> jsme dodali <b>moderní šmrnc</b>! Dveře této <b>velmi oblíbené</b> řady zútulní a prohřejí váš byt, a přitom <b>nevyjdou z módy</b>.</p>'+
            '<p>Můžete si vybrat z několika variant s <b>různou skladbou rámečků a prosklení</b>. Barvu dveří snadno sladíte s interiérem díky volbě z <b>34 druhů dřevěných dekorů!</b> Povrchy jsou vysoce odolné a snadno se vám budou udržovat. Jejich životnost prodlouží <b>tvrdá hliníková hrana</b>.</p>'+
            '<p>K šatně nebo do chodby budou zase ideální jako dveře <b>posuvné</b>. A samozřejmě si můžete zvolit, zda chcete dveře <b>falcové</b>, třeba <b>s oblou hranou</b>, nebo dveře <b>bezfalcové</b>.</p>',
          url: 'https://www.solodoor.cz/cs/interierove-dvere/styl/',   
          list: [
            {
              id: 'styl-15',
              name: 'styl 15',
              configPath: 'https://www.sestavsidvere.cz/cs/styl/styl-15/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/11/b11015842_2300010.jpg',
            },
            {
              id: 'styl-16',
              name: 'styl 16',
              configPath: 'https://www.sestavsidvere.cz/cs/styl/styl-16/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/11/b11016842.jpg',
            },
            {
              id: 'styl-17',
              name: 'styl 17',
              configPath: 'https://www.sestavsidvere.cz/cs/styl/styl-17/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/11/b11017842_2300010.jpg',
            },
            {
              id: 'styl-18',
              name: 'styl 18',
              configPath: 'https://www.sestavsidvere.cz/cs/styl/styl-18/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/11/b11018842.jpg',
            },
            {
              id: 'styl-21',
              name: 'styl 21',
              configPath: 'https://www.sestavsidvere.cz/cs/styl/styl-21/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/11/b11021842_2300010.jpg',
            },
            {
              id: 'styl-28',
              name: 'styl 28',
              configPath: 'https://www.sestavsidvere.cz/cs/styl/styl-28/?mena=czk&lng=cs',
              imgPath: 'https://www.sestavsidvere.cz/foto/11/b11028842.jpg',
            }
          ]
        }

      ],

      interiorZarub: [
        {
          id: 'oblozkova-zaruben',
          name: 'Obložková zárubeň pro bezfalcové dveře',
          title: 'Obložková zárubeň pro bezfalcové dveře',
          imgPath: 'zarub/oz.jpg',
          description: 'Tento druh obložkové zárubně využíváme speciálně pro bezfalcové dveře, které nelze instalovat do klasické falcové zárubně. Největší výhodou je jednotná rovina zárubní i dveří při zavřeném stavu a perfektní vzhled celého dveřního kompletu. Skryté závěsy neruší pohled ani z jedné strany dveří a umožňují seřízení ve všech třech směrech, díky čemuž dveře perfektně zapadnou do zárubně.'
        },
        {
          id: 'standardni-zaruben',
          name: 'Standardní zárubeň',
          title: 'Standardní obložková zárubeň',
          imgPath: 'zarub/sz.jpg',
          description: 'Kvalitní zárubně jsou základem pro montáž všech interiérových dveří. Dnes se zákazník na trhu nejčastěji setká s dřevěnými obložkovými zárubněmi. Oblíbené jsou především díky jejich variabilitě použití pro montáž do stavebního otvoru jak ve zděné a sádrokartonové, tak i ve dřevěné zdi.<br/><br/>SOLODOOR a.s. navíc nabízí mnoho typů povrchů, dekorů a různých kombinací obložkových zárubní. Spojení zárubně a dveří bude působit harmonicky, pokud vyberete zárubně a dveře se stejnou povrchovou úpravou.'
        },
        {
          id: 'obklad-ocelove-zarubne',
          name: 'Obklad ocelové zárubně',
          title: 'Obklad ocelové zárubně',
          imgPath: 'zarub/ooz.jpg',
          description: 'Díky tomuto modernímu a atraktivnímu způsobu renovace starších interiérů můžete mít nové dveře v rekordně krátkém čase, a to bez bourání i větších stavebních úprav.<br/><br/>Obklad ocelové zárubně se totiž montuje na původní kovovou zárubeň pomocí montážní pěny – z původní zárubně stačí odstranit panty. Pro panelové domy s užšími ocelovými zárubněmi pak doporučujeme obklad obložkové zárubně Speciál. Ten je používán speciálně pro obložení těchto užších ocelových zárubní, které jsou běžně používané především v panelových domech.<br/><br/>V případech, kdy není možné použít kompletní obklad ocelové zárubně (např. u dveří požárně odolných), nabízíme možnost částečného obkladu ocelové zárubně.'
        }
      ],

      posuvne:  [
        {
          id: 'dopouzdra',
          name: 'Posuvné dveře do pouzdra',
          description: '<p><b>Posuvné dveře do zdi</b>, tedy do stavebního pouzdra, jsou čím dále oblíbenější způsob otevírání dveří. Posuvné dveře do pouzdra přináší proti klasickým dveřím výraznou úsporu místa, jelikož se zasouvají do stěny která vznikne instalací stavebního pouzdra.</p>' +
            '<p>Pouzdra pro dveře mohou být vybavena i přípravou pro instalaci vypínače a jejich montáž tak může být rychlá a jednoduchá.</p>' +
            '<h2>Výhody posuvných dveří do pouzdra</h2>' + 
            '<ul class="list"><li>Úspora místa.</li>' +
            '<li>Snadný a tichý chod při otevírání dveří.</li>' +
            '<li>Dlouhá životnost a snadná údržba.</li>' +
            '<li>Vhodné pro bezbariérový přístup.</li>' +
            '<li>Zajímavý a designový prvek.</li></ul>' 
        },
        {
          id: 'nastenu',
          name: 'Posuvné dveře na stěnu',
          description: '<p>Posuvné dveře na zeď vypadají moderně a elegantně a ve vteřině dokáží pouhým pohybem ruky přepažit velkou místnost na dvě menší. Posuvné dveře na zeď také dokáží u širokých průchodů z místnosti do místnosti (např. jídelna a obývací pokoj), kde by běžné otočné dveře kvůli svému velkému rádiusu nepřicházely v úvahu, ušetřit spoustu místa.</p>' +
            '<h2>Proč posuvné dveře na stěnu, a ne do pouzdra?</h2>' +
            '<p>Pokud zrovna nestavíte, nechcete bourat a sekat do zdi, nebo do zdi nemůžete zasáhnout (např. do nosné zdi kvůli narušení statiky), zvolte raději posuvné dveře na stěnu. Posuvným dveřím na stěnu, na rozdíl od posuvných dveří do pouzdra , stačí pouze kolejnice, která se jednoduše připevní z jedné strany zdi nad dveře.</p>' +
            '<h3>Výhody</h3>' + 
            '<ul class="list"><li>Dveře šetří místo tím, že se neotevírají do prostoru pokoje</li>' +
            '<li>Na rozdíl od posuvných dveří do pouzdra není potřeba instalovat dveřní pouzdro, ale pouze garnýž s kolejnicí</li>' +
            '<li>Instalace posuvných dveří na stěnu je oproti instalaci posuvných dveří do pouzdra rychlejší, levnější a obejde se bez bourání</li>' +
            '<li>Dveře jsou bezbariérové, protože nemají práh.</li>' +
            '<li>Tiše a snadno se otevírají a zavírají</li></ul>' +
            '<h3>Nevýhody</h3>' + 
            '<ul class="list"><li>"Nezmizí" do zdi jako posuvné dveře do pouzdra</li>' +
            '<li>V dráze dveří nesmí nic překážet (nábytek, vypínače atp.)</li></ul>' +
            '<p>Zaujaly vás posuvné dveře na stěnu? Kontaktujte svého nejbližšího prodejce Solodoor nebo si sestavte vlastní dveře v našem konfigurátoru dveří.</p>'
        },
        {
          id: 'patriot',
          name: 'Posuvné dveře do pouzdra',
          description: '<p>Posuvný sytém PATRIOT je elegantním a moderním prvkem pro bytový i komerční interiér, podtrhuje krásu každého prostoru. Pohyb dveřního křídla je plynulý díky integrovanému zpomalovacímu a tlumícímu sytému. Instalace je velmi jednoduchá, obejde se bez bourání nebo sekání do zdi.</p>' +
            '<p>Posuvný systém PATRIOT je složen z pojezdové lišty uchycené na zeď a pojezdových mechanismů. Dveře jsou vedeny spodním vodícím trnem v podlaze. Kromě originálního designového řešení tak představuje posuvný systém PATRIOT i řešení vysoce praktické – na podlaze není žádná lišta, která by se mohla zanášet nečistotami, což velmi usnadní úklid.</p>' +
            '<h2>Výhody posuvného systému PATRIOT</h2>' + 
            '<ul class="list"><li>Originální řešení prostoru</li>' +
            '<li>Bezbariérové</li>' +
            '<li>Nenáročné na prostor</li>' +
            '<li>Jednoduché a bezhlučné otevírání</li>' +
            '<li>Tichý dojezd</li></ul>' 
        },
      ],

      hrany: [
        {
          id: 'obla-hrana',
          name: 'Oblá hrana',
          title: 'OBLÁ HRANA DVEŘÍ …perfektní v každém směru',
          imgPath: 'hrany/oh.jpg',
          description: '<h2>Co pro naše dveře přináší oblá hrana?</h2>' +
            '<ul class="list"><li>Elegantní vzhled dveří i zárubní</li>' +
            '<li>Rádius dveří i oblé hrany perfektně sladěny s rádiusem zárubně</li>' +
            '<li>Neviditelné napojení hranovací pásky</li>' +
            '<li>Není vidět špína na hraně</li>' +
            '<li>Snižuje riziko úrazu při pádu na hranu dveří</li>' +
            '<li>Snižuje riziko poškození dveří při nárazu jiným předmětem</li>' +
            '<li>Omezuje riziko odlepování namáhaných hran dveří</li>' +
            '<li>Zabraňuje průniku vzdušné vlhkosti do dveřního křídla</li>' +
            '<li>Praktičnost při čištění a údržbě dveří</li>' +
            '<li>Dostupné pro povrchy SOLO 3D, CPL, fólie</li>' +
            '</ul>' +
            '<p>Oblá hrana dveří je naše chlouba a jedna z výhod oproti konkurenci. Abychom vám ji mohli nabídnout, vyrábíme ji pomocí v Česku jedinečné technologie dovezené ze zahraničí. Jaké jsou vlastně její výhody?</p>' +
            '<p>Oblé hrany působí velmi harmonicky a přináší více než jen originální design. Tyto dveře mají řadu pozitiv, mezi něž patří i větší bezpečnost a odolnost dveří. Při pádu na hranu dveří nebo při jiném kontaktu se dveřmi oblá hrana snižuje riziko úrazu, takže přispívá k většímu bezpečí vaší rodiny.  Všichni víme, jak dokáže bolet i hloupý nakopnutý malíček. Oblé hrany také snižují riziko odlepení namáhaných hran a zabraňují průniku vzdušné vlhkosti do dveřního křídla.</p>' +
            '<p>Oblé hrany jsou moderním designovým prvkem, který vašemu interiéru dodá jedinečný styl. U dveří Solodoor je kladen důraz na špičkový design, který je domyšlen do posledních detailů. Rádius oblé hrany je u těchto dveří perfektně sladěný s rádiusem zárubně, takže dveře působí velmi harmonicky a elegantně. Oblá hrana je bez příplatku, a můžete si ji proto snadno dovolit u kterýchkoliv dveří.</p>' +
            '<p>Investovali jsme do nejlepších technologií, abychom dali našim dveřím opět něco navíc.</p>' +
            '<p>Unikátní řešení olé hrany je nejenom krásným designovým prvkem – výrazně také zlepšuje užitné vlastnosti dveří</p>' +
            '<p>Standardně je oblá hrana provedena na bočních hranách a horní hraně (kromě řady Vertigo s provedením pouze na svislých hranách).</p>' +
            '<p>Na přání zákazníka je možné dodat provedení oblé hrany pouze na svislých hranách.</p>' +
            '<h3>Oblá hrana je možná pro modelové řady:</h3>' +
            '<p>Vertigo (provedení pouze na svislých hranách), Sima, Solo, Gabreta, Song, Styl, Klasik.</p>'
        },
        
        {
          id: 'hrana-magic',
          name: 'Hrana magic',
          title: 'Hrana magic',
          description: '' 
        },
        {
          id: 'al-hrana',
          name: 'Al hrana',
          title: 'Tvrdá hliníková hrana u dveří',
          imgPath: 'hrany/al.jpg',
          description: '<img src="images/hrany/al-img.jpg" alt="Al hrana" class="w-100"/>'+
            '<p>Hledáte dveře s originálním designovým prvkem?  Očekáváte od dveří něco víc než jen prosté oddělení dvou pokojů? Chcete odolné a pevné dveře, které něco vydrží? Vsaďte na dveře s tvrdou hliníkovou hranou.</p>' +
            '<p>Tato novinka na trhu má kromě své jedinečné estetické funkce především praktické výhody: tvrdá hliníková hrana výrazně zvyšuje konstrukční pevnost a odolnost dveří. To znamená, že dveře vydrží prudké nárazy, odolají náporu vašich dětí i zvířecích mazlíčků a výborně se osvědčí i v prostředí se zvýšeným provozem.  Tvrdá hliníková hrana je možná u většiny modelových řad. Nejlépe však vynikne u dveří, jejichž design je ozvláštněn kovovým prvkem, jako jsou například dveře z modelové řady EXCELENT nebo ELEGANT, doporučeným povrchem je CPL Tvrdou hliníkovou hranou je možné opatřit jak falcové, tak i bezfalcové dveře.</p>' +
            '<p>Tvrdá hliníková hrana zvyšuje životnost dveří a pro svou vysokou odolnost jsou dveře s touto úpravou vhodné i do vysoce namáhaných prostor, jako jsou díly, školy, obchody či sportovní zařízení.</p>'
        },
        {
          id: 'falc-rovny',
          name: 'Falc rovný',
          title: 'Falc rovný',
          description: ''
        },
        {
          id: 'bezfalcova-hrana',
          name: 'Bezfalcová hrana',
          title: 'Bezfalcové dveře',
          imgPath: 'hrany/bf.jpg',
          description: '<p>Sázíte ve svém bytě na prostornost a jednoduchý moderní design? Pak určitě zvolte bezfalcové dveře.</p>' +
            '<p>Bezfalcové dveře (někdy tako bývají označovány jako bezpolodrážkové) mají totiž dveřní křídlo zarovnané do jedné roviny se zárubněmi a nevyčnívají ani o milimetr. Jejich panty jsou navíc skryté, takže ani ty nenaruší celkovou čistotu plochy.</p>' +
            '<p>Nejde ale jen o přísný design. Bezfalcové interiérové dveře umožňují dokonalé zavírání a jejich obliba mezi současnými architekty stále roste. Ať už máte byt vrcholně moderní nebo spíše konzervativně zařízený, dodají mu v každém případě luxusní šarm. Hodí se prakticky kamkoli, jen nesmíte zapomenout objednat je i se zárubní – zárubeň pro klasické falcové dveře k nim nepasuje.</p>' +
            '<p>Zda je u jednotlivých modelů dveří možné bezfalcové provedení, je uvedeno v katalogu ve spodní části stránky. Které dveře myslíte, že by se nejlépe hodily vám?</p>' 
        
        },
        {
          id: 'falc-sikmy',
          name: 'Falc šikmý',
          title: 'Šikmý falc',
          imgPath: 'hrany/fs.jpg',
          description: '<p>Inspirovali jsme se v Itálii, světové mecce interiérového designu a vyvinuli jsme pro Vás dveře se <b>zcelou novou variantou hrany</b>. Ta je opracována v šikmém směru, a proto celý dveřní set působí opticky jemněji. Samozřejmostí je <b>obalení hrany ochrannou páskou</b> podtrhující výsledný dojem.</p>' +
            '<p><b>Nemáte rádi nudu a konzervativní řešení? Baví Vás osobité řešení detailu? Pak vsaďte na dveře s šikmým falcem.</b></p>' +
            '<p><b>Výhody šikmého falcu:</b></p>' +
            '<ul class="list"><li>Zaměřeno na detail</li>' +
            '<li>Originální řešení hrany</li>' +
            '<li>Preciznost zpracování</li>' +
            '<li>Ochranná hranovací páska ve stejném dekoru</li>' +
            '<li>Dveřní set působí opticky jemněji</li>' +
            '</ul>' 
        }
      ]
    },
    mutations: {},
    actions: {}
  })