
import { defineComponent, ref, onMounted, watch } from 'vue'

import { useRoute, useRouter } from 'vue-router'

import "@/assets/scripts/nav.js"

export default defineComponent({
    name: 'Nav',
    setup() {
        const route = useRoute()

        var burgerCheck = ref(false)
        const refNav = ref<null | { clientHeight: () => null}>(null)

        function openBurger() {
            burgerCheck.value = !burgerCheck.value
        }

        watch(route, () => {
                burgerCheck.value = false
            }
        )

        return {
            refNav,
            route,
            burgerCheck,
            openBurger
        }
  },
   watch: {
      $route (to, from) {
          if(from.name == "Produkty" && to.name != "Produkty") {
              setTimeout(() => {
                    if(this.$router.currentRoute.value!.hash) {
                        var el = document.querySelector(this.$router.currentRoute.value!.hash)
                        var offsetPos = el!.getBoundingClientRect().top - 100
                        window.scrollTo({top: offsetPos})
                    }
              }, 500);
          } else if (from.name == "Produkty" && to.name == "Produkty" && window.outerWidth <= 640) {
                var el = document.getElementById("aside")
                var offsetPos = el!.offsetHeight + 70
                console.log(offsetPos)
                window.scrollTo({top: offsetPos})
          } else if (from.name != "Produkty" && to.name == "Produkty") {
                window.scrollTo({top: 0})
          }
      } 
  }
});
